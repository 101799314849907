// @ts-nocheck
import { ref } from "vue"
import { defineStore } from "pinia";
import { useProfile } from '@/stores/profile'
import { User } from '@auth0/auth0-vue';

export const usePendoStore = defineStore("pendo", () => {
	const user = ref<User | null>(null);
	const apiKey = import.meta.env.VITE_PENDO_API_KEY as string;

	const init = (u: User) => {
		// Don't track in development environments.
		if (!shouldTrack()) return;

		user.value = u;

		/* eslint-disable */ 
        (function (p, e, n, d, o) {
            var v, w, x, y, z; o = p[d] = p[d] || {}; o._q = o._q || [];
            v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track']; for (w = 0, x = v.length; w < x; ++w)(function (m) { o[m] = o[m] || function () { o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0))); }; })(v[w]);
            y = e.createElement(n); y.async = !0; y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
            z = e.getElementsByTagName(n)[0]; z.parentNode.insertBefore(y, z);
        })(window, document, 'script', 'pendo');
        /* eslint-enable */ 
	}

	const track = (metadata: Object = {}) => {
		// Don't track in development environments.
		if (!shouldTrack()) return;
		if (!user.value) console.error('Pendo is not initialized');

		const storeProfile = useProfile();

        pendo.initialize({
			visitor: {
				id: storeProfile.isTRs ? `truser-${user.value.sub}` : user.value.sub,
				email: user.value.email,
				full_name: user.value.name,
				roles: Object.values(user.value['https://trueroll.io/roles'] || []),
				...metadata
			},
			account: {
				...storeProfile.getCustomerAccess,
				id: storeProfile.getCustomerAccess?.customer_id,
				name: storeProfile.getCustomerAccess?.name// Optional
			}
		})
	}

	const shouldTrack = () => {
		if (import.meta.env.MODE === 'development' && import.meta.env.DEV === true) {
			// Don't track in development environments.
			console.warn('Pendo is not tracking in development environment');
			return false;
		}

		return true;
	}

	return {
		init,
		track,
	}

})