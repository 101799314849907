<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2>Scheduled Tasks</h2>
        <div class="card">
          <div class="card-body">
            <div class="mb-3">
              <button class="btn btn-primary" @click="showAddModal = true">
                Add New Schedule
              </button>
            </div>

            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>Enabled</th>
                    <th>Schedule Name</th>
                    <th>Task Name</th>
                    <th>Schedule (crontab m/h/dM/MY/d)</th>
                    <th>Last Run</th>
                    <th>Total Runs</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="task in tasks" :key="task.name">
                    <td><i :class="task.enabled ? 'fa-solid fa-play' : 'fa-solid fa-pause'"></i></td>
                    <td>{{ task.name }}</td>
                    <td>{{ task.task }}</td>
                    <td>{{ task.schedule }}</td>
                    <td>{{ formatDate(task.last_run_at) }}</td>
                    <td>{{ task.total_run_count }}</td>
                    <td>
                      <button class="btn btn-sm btn-primary me-2" @click="editTask(task)">
                        Edit
                      </button>
                      <button class="btn btn-sm btn-danger" @click="deleteTask(task.name)">
                        Delete
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Add/Edit Task Modal -->
    <div class="modal fade" :class="{ show: showAddModal || showEditModal }" tabindex="-1" v-if="showAddModal || showEditModal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ showEditModal ? 'Edit Task' : 'Add New Task' }}</h5>
            <button type="button" class="btn-close" @click="closeModal"></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="saveTask">
              <div class="mb-3">
                <label class="form-label">Enabled</label>
                <input type="checkbox" class="form-check-input" v-model="currentTask.enabled">
              </div>
              <div class="mb-3">
                <label class="form-label">Schedule Name</label>
                <input type="text" class="form-control" v-model="currentTask.name" required :disabled="showEditModal">
              </div>
              <div class="mb-3">
                <label class="form-label">Task Name</label>
                <input type="text" class="form-control" v-model="currentTask.task" required>
              </div>
              <div class="mb-3">
                <label class="form-label">Schedule (crontab)</label>
                <input type="text" class="form-control" v-model="currentTask.schedule" required>
                <small class="text-muted">Format: * * * * * (minute hour day month weekday)</small>
              </div>
              <div class="mb-3">
                <label class="form-label">Arguments (JSON array)</label>
                <input type="text" class="form-control" v-model="currentTask.args">
              </div>
              <div class="mb-3">
                <label class="form-label">Keyword Arguments (JSON object)</label>
                <input type="text" class="form-control" v-model="currentTask.kwargs">
              </div>
              <div class="mb-3">
                <label class="form-label">Options (JSON object)</label>
                <input type="text" class="form-control" v-model="currentTask.options">
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeModal">Cancel</button>
            <button type="button" class="btn btn-primary" @click="saveTask">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useAPI } from "@/helpers/services/api"
import { formatDate, getApiErrorMessage } from '@/helpers/common'
import { toast } from "@/helpers/toast"
import type { AxiosError } from 'axios'

const api = useAPI()

interface ScheduledTask {
  name: string
  task: string
  schedule: string
  args: any[] | null
  kwargs: Record<string, any> | null
  options: Record<string, any> | null
  last_run_at: string | null
  total_run_count: number
  enabled: boolean
}

interface ScheduledTaskForm {
  name: string
  task: string
  schedule: string
  args: string
  kwargs: string
  options: string
  enabled: boolean
}

const tasks = ref<ScheduledTask[]>([])
const showAddModal = ref(false)
const showEditModal = ref(false)
const currentTask = ref<ScheduledTaskForm>({
  name: '',
  task: '',
  schedule: '',
  args: '',
  kwargs: '',
  options: '',
  enabled: true
})

const fetchTasks = async () => {
  try {
    const response = await api.get('/scheduled-tasks/')
    tasks.value = response.data
  } catch (error) {
    toast.error(
      getApiErrorMessage(error as AxiosError, { "featureName": "Scheduled Tasks" })
    )
  }
}

const saveTask = async () => {
  try {
    const taskData = {
      name: currentTask.value.name,
      task: currentTask.value.task,
      schedule: currentTask.value.schedule,
      args: currentTask.value.args ? JSON.parse(currentTask.value.args) : null,
      kwargs: currentTask.value.kwargs ? JSON.parse(currentTask.value.kwargs) : null,
      options: currentTask.value.options ? JSON.parse(currentTask.value.options) : null,
      enabled: currentTask.value.enabled
    }
    if (showEditModal.value) {
      await api.patch(`/scheduled-tasks/${currentTask.value.name}`, taskData)
      toast.success('Task updated successfully')
    } else {
      await api.post('/scheduled-tasks/', taskData)
      toast.success('Task created successfully')
    }
    closeModal()
    fetchTasks()
} catch (error) {
    toast.error("Error parsing arguments: " + error)
  }
}

const deleteTask = async (name: string) => {
  if (!confirm('Are you sure you want to delete this task?')) return

  try {
    await api.delete(`/scheduled-tasks/${name}`)
    toast.success('Task deleted successfully')
    fetchTasks()
  } catch (error) {
    toast.error(getApiErrorMessage(error as AxiosError, { "featureName": "Scheduled Tasks" }))
  }
}

const editTask = (task: ScheduledTask) => {
  currentTask.value = {
    name: task.name,
    task: task.task,
    schedule: task.schedule,
    args: task.args ? JSON.stringify(task.args) : '',
    kwargs: task.kwargs ? JSON.stringify(task.kwargs) : '',
    options: task.options ? JSON.stringify(task.options) : '',
    enabled: task.enabled
  }
  showEditModal.value = true
}

const closeModal = () => {
  showAddModal.value = false
  showEditModal.value = false
  currentTask.value = {
    name: '',
    task: '',
    schedule: '',
    args: '',
    kwargs: '',
    options: '',
    enabled: true
  }
}

onMounted(() => {
  fetchTasks()
})
</script>

<style scoped>
.modal.show {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
}
</style> 