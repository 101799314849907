<template>
    <div ref="appVetDataView" id="appVetDataView">
        <div class="row action-toolbar-container" id="page-header-action-section">
            <div class="col-lg-8 col-md-12 mt-2" v-if="!loading">
                <Button :disabled="totalRecords <= 0 || isShowAllSelectedItems" v-if="!toggledSelectAll" id="select-all"
                    type="button" outlined class="me-2" @click="onSelectAllChange()" severity="secondary">
                    <span class="fa fa-check-square" aria-hidden="true"></span> Select All
                </Button>
                <Button v-else :disabled="isShowAllSelectedItems" id="deselect-all" type="button" outlined class="me-2"
                    @click="clearSelection()" severity="secondary">
                    <span class="fa fa-square-minus" aria-hidden="true"></span> Clear Selection
                </Button>
                <Button type="button" class="me-2" @click="onStatusButtonClick(ApplicationStatus.Approved)"
                    :disabled="(selectedItems.length <= 0 && !isRecordsInCurrentPageSelected)">
                    <span class="fa fa-check" aria-hidden="true"></span> Approve
                </Button>
                <Button type="button" class="me-2" @click="onStatusButtonClick(ApplicationStatus.Denied)"
                    severity="danger" :disabled="(selectedItems.length <= 0 && !isRecordsInCurrentPageSelected)">
                    <span class="fa fa-times" aria-hidden="true"></span> Deny
                </Button>
                <Button type="button" class="me-2" @click="onStatusButtonClick(ApplicationStatus.Snooze)"
                    :disabled="(selectedItems.length <= 0 && !isRecordsInCurrentPageSelected)" severity="secondary">
                    <span class="fa fa-clock" aria-hidden="true"></span> Snooze
                </Button>
                <SplitButton label="Assign" rounded class="me-2" :model="assignActionItems" id="assign-action-item"
                    :disabled="(selectedItems.length <= 0 && !isRecordsInCurrentPageSelected)"
                    @click="onBulkActionClick('ASSIGN')">
                    <span class="fa fa-user-plus" aria-hidden="true"></span>
                    Assign
                </SplitButton>
                <Button type="button" class="me-2" id="label-action-item" @click="onBulkLabelClick" severity="info"
                    :disabled="(selectedItems.length <= 0 && !isRecordsInCurrentPageSelected)">
                    <span class="fa fa-tags" aria-hidden="true"></span>
                    Labels
                </Button>
                <Button type="button" class="me-2" id="note-action-item" @click="onAddNoteClick" severity="info"
                    :disabled="(selectedItems.length <= 0 && !isRecordsInCurrentPageSelected)">
                    <span class="fa fa-comment" aria-hidden="true"></span> Note
                </Button>
                <Button type="button" class="me-2" @click="onExportClick" id="export-action-item"
                    :disabled="(selectedItems.length <= 0 && !isRecordsInCurrentPageSelected)" severity="info">
                    <span class="fa fa-download" aria-hidden="true"></span> Export
                </Button>
            </div>
            <div class="col-lg-8 col-md-12 mt-2 d-inline-flex" v-else>
                <Skeleton width="115px" height="34px" class="ms-2 mb-2"></Skeleton>
                <Skeleton width="145px" height="34px" class="ms-2 mb-2"></Skeleton>
                <Skeleton width="95px" height="34px" class="ms-2 mb-2"></Skeleton>
            </div>
            <div class="col-lg-4 col-md-12 mt-2">
                <StrapCardHeaderActions v-model:quickSearchQuery="quickSearchQuery" v-model:filters="filters"
                    :quickSearchQueryTitle="quickSearchQueryTitle" :sortTitles="sortTitles" :filterConfig="filterConfig"
                    :loading="loading" :sortOrder="sortOrder" @changeSortOrder="changeSortOrder"
                    :isShowAllSelectedItems="isShowAllSelectedItems" :parentDataView="appVetDataView"
                    @removeFilters="handleRemoveFilters" @submitFilters="handleSubmitFilters"
                    @onFiltersDirtyChanged="onFiltersDirtyChanged" />
            </div>
        </div>

        <div v-show="selectedItems.length > 0 && (!isRecordsInCurrentPageSelected && !isAllRecordsMatchingFiltersSelected) && !loading"
            class="text-center list-header-select-all-message">
            <Message class="mb-2" severity="secondary" :closable="false">
                You have selected
                <a v-tooltip.bottom="`View selected ${recordLabel}`" href="javascript:;" @click="showAllSelectedItems()"
                    id="view-all-selected">
                    {{ selectedItems.length }} {{ recordLabel }}.
                </a>
                <a href="javascript:;" @click="clearAllSelectedItems()">Clear Selection </a>
            </Message>
        </div>

        <div v-show="(isRecordsInCurrentPageSelected || isAllRecordsMatchingFiltersSelected) && !loading"
            class="text-center list-header-select-all-message">
            <Message class="mb-2" severity="secondary" :closable="false">
                <span v-if="!isAllRecordsMatchingFiltersSelected">
                    {{ formatNumberWithCommas(selectedItems.length) }} {{ recordLabel }} on the current page are
                    selected.
                    <a v-if="!isAllRecordsMatchingFiltersSelected && (selectedItems.length < totalRecords)"
                        href="javascript:;" @click="toggleSelectAllMatchingFilters()">
                        Select all {{ formatNumberWithCommas(totalRecords) }} {{ recordLabel }} matching current
                        filters
                    </a>
                </span>
                <span v-else>
                    All {{ formatNumberWithCommas(totalSelectedApplication) }} {{ recordLabel }} selected,
                    deselection by
                    individual record disabled
                    <label
                        v-tooltip.bottom="'Deselection is only possible on a per-page basis. At the bottom of the list, you can increase the page size from the default of 25 up to 500 if necessary.'">
                        <span class="fa fa-circle-info"></span>
                    </label>
                    <a v-if="isAllRecordsMatchingFiltersSelected" href="javascript:;" @click="clearAllSelectedItems()">
                        Clear Selection
                    </a>
                </span>
            </Message>
        </div>

        <div v-show="showQSMessage" class="text-center list-header-select-all-message">
            <Message class="mb-2" severity="secondary" :closable="false"
                v-if="(totalRecords === 0) && filteringWithQS && !isArchiveQueue">
                <!-- If searching through inbox or snooze -->
                Would you like to search through
                <a href="javascript:;" @click="() => quickSearchArchive()">
                    archived {{ recordLabel }}
                </a>
                instead?
            </Message>
        </div>

        <div v-if="loading">
            <div class="dataview-list-row-card"></div>
            <div v-for="i in 5" :key="i" class="row dataview-list-row-card">
                <div class="col-1 align-content-center" id="selectionColumn">
                    <div class="p-3">
                        <Skeleton width="100%" size="2rem" class="me-2"></Skeleton>
                    </div>
                </div>
                <div class="col-lg-3 col-md-12 align-content-center">
                    <div class="p-3">
                        <Skeleton width="100%" height="1rem" class="mb-2"></Skeleton>
                        <Skeleton width="60%" height="1rem" class="mb-2"></Skeleton>
                        <Skeleton width="50%" height="2rem" class="mb-2"></Skeleton>
                    </div>
                </div>
                <div class="col-lg-2 col-md-6 align-content-center">
                    <div class="p-3">
                        <Skeleton width="50%" height="1rem" class="mb-2"></Skeleton>
                        <Skeleton width="80%" height="1rem" class="mb-2"></Skeleton>
                    </div>
                </div>
                <div class="col-lg-2 col-md-6 align-content-center">
                    <div class="p-3">

                        <Skeleton width="70%" height="1rem" class="mb-2"></Skeleton>
                        <Skeleton width="90%" height="1rem" class="mb-2"></Skeleton>
                    </div>
                </div>
                <div class="col-lg-2 col-md-12 align-content-center">
                    <div class="p-3">
                        <Skeleton width="100%" height="1rem" class="mb-2"></Skeleton>
                        <div class="d-flex mb-2">
                            <Skeleton width="50%" height="1rem" class="me-2"></Skeleton>
                            <Skeleton width="30%" height="1rem" class="me-2"></Skeleton>
                            <Skeleton width="80%" height="1rem" class="me-2"></Skeleton>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 col-md-12 align-content-center" v-if="!isArchiveQueue">
                    <div class="p-3 d-flex">
                        <Skeleton width="30%" size="3rem" class="me-2"></Skeleton>
                        <Skeleton width="30%" size="3rem" class="me-2"></Skeleton>
                        <Skeleton width="30%" size="3rem" class="me-2"></Skeleton>
                        <Skeleton width="30%" size="3rem" class="me-2"></Skeleton>
                        <Skeleton width="30%" size="3rem" class="me-2"></Skeleton>
                    </div>
                </div>
                <div v-else class="col-lg-2 col-md-12 align-content-center">
                    <Skeleton width="10%" height="1rem" class="mb-2"></Skeleton>
                    <Skeleton width="70%" height="1rem" class="mb-2"></Skeleton>
                </div>
            </div>
        </div>

        <BasicStrapCards :loading="loading" :data="data" :firstIndex="first" :rows="rows" :componentID="props.queue[0]"
            :totalRecords="totalRecords" @onpage="(event: any) => onPage(event)" :parentDataView="appVetDataView"
            :key="paginationResetCounter">
            <template #list="slotProps">
                <div v-for="(item, index) in (slotProps.data as ApplicationVetter[])" :key="index"
                    class="row dataview-list-row-card" :id="`row_${index}`"
                    :class="{ 'attention-needed fw-bold': isSnoozeQueue && isSnoozeExpired(item.snooze_until) }">
                    <div class="col-1 align-content-center" id="selectionColumn">
                        <div class="p-2">
                            <Checkbox v-model="selectedItems" :inputId="item.tru_id" name="category"
                                :value="item.tru_id" />
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-12 align-content-center clipboard-copy-wrapper">
                        <div class="p-2">
                            <div class="mb-2">
                                <a :href="'/application-details/' + item.tru_id" target="_blank">
                                    {{ item.application_id }}
                                </a>
                            </div>
                            <div class="mb-2">
                                Parcel {{ item.parcel_num }}
                                <span v-if="item.parcel_num">
                                    <a href="javascript:;" v-tooltip="'Add to Quick Search'"
                                        class="quick-search-link mx-2" @click="addToQuickSearch(item.parcel_num)">
                                        <span class="fa fa-search fa-sm"></span>
                                    </a>
                                    <a href="javascript:;" v-tooltip.bottom="'Copy to Clipboard'"
                                        class="copy-to-clipboard" @click="copyTextToClipboard(item.parcel_num)">
                                        <span class="fa-regular fa-copy fa-sm"></span>
                                    </a>
                                </span>
                            </div>
                            <div class="mb-2" id="applicant">
                                {{ item.applicant }} <br>
                                {{ item.applicant_2 }}
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-6 align-content-center">
                        <div class="p-2">

                            <div> {{ item.exemptions?.join(", ") }} </div>
                            <div v-if="item.effective_date" class="mt-2">
                                Effective {{ formatDate(item.effective_date) }}
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-6 align-content-center">
                        <div class="p-2">
                            <div v-if="item.source" class="mb-2">
                                {{ item.source }}
                            </div>
                            <div>
                                <div v-if="isArchiveQueue" class="mb-2">
                                    Submitted on {{ formatDate(item.submitted_date) }}
                                </div>
                                <div v-else class="mb-2" :title="formatDate(item.submitted_date)">
                                    <span
                                        v-if="(item._days_elapsed = dayjs().diff(item.submitted_date?.split('T')[0], 'day'))">
                                        Submitted {{
                                            item._days_elapsed.toString() + (item._days_elapsed == 1 ? " day" : " days")
                                        }} ago
                                    </span>
                                    <span v-else>Less than a day</span>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="col-lg-2 col-md-12 align-content-center">
                        <div class="my-2 p-2">
                            <span v-if="item.assigned_user_id">
                                <Chip :label="item.assigned_user"
                                    class="me-2 mb-1 tr-chip assigned-to-chip cursor-pointer"
                                    @click="displayUserInfo($event, item.assigned_user_id)" />
                            </span>
                            <span v-else>
                                <i>Unassigned</i>
                            </span>
                        </div>
                        <div v-if="item.labels" class="d-block mb-2">
                            <span v-for="(label, index) in item.labels" :key="index">
                                <Chip :label="label" class="me-2 mb-1 tr-chip label-chip" />
                            </span>
                        </div>
                    </div>
                    <div :class="{ 'col-lg-3': showCheckIcon, 'col': isArchiveQueue || isSnoozeQueue || isAllQueue}"
                        class="align-content-center">
                        <div v-if="showCheckIcon" class="p-2 d-flex mb-2">
                            <ApplicationVetterStatusCheckIcon :title="'Compliant Submission'" :iconClass="'fa-file'"
                                :status="item.logic_check" />

                            <ApplicationVetterStatusCheckIcon :title="'ID Verification'" :iconClass="'fa-id-card'"
                                :status="item.id_check" />

                            <ApplicationVetterStatusCheckIcon :title="'Applicants Match Deed'" :iconClass="'fa-scroll'"
                                :status="item.deed_check" />

                            <ApplicationVetterStatusCheckIcon :title="'Multiple Homesteads'" :iconClass="'fa-house'"
                                :status="item.multi_hs_check" />

                            <ApplicationVetterStatusCheckIcon :title="'Enhanced'" :iconClass="'fa-certificate'"
                                :status="item.other_check" />
                        </div>
                        <div v-else-if="isArchiveQueue" class="p-2">
                            <div class="mb-2">
                                {{ ucfirst(item.application_status) }}
                                <span v-if="item.last_edited_by_username">
                                    by {{ item.last_edited_by_username || "-" }}
                                </span>
                            </div>
                            <div class="mb-2">
                                on {{ formatDate(item.last_edited_at) }}
                            </div>
                        </div>
                        <div v-else-if="isSnoozeQueue" class="p-2">
                            <div class="mb-2" v-if="item.snooze_until">
                                Snoozed
                                <span v-if="isSnoozeExpired(item.snooze_until)">expired</span>
                                <span v-else>until</span>
                                {{ formatDate(item.snooze_until) }}
                            </div>
                            <div v-else>-</div>
                        </div>
                        <div v-else-if="isAllQueue" class="p-2">
                            <div class="mb-2" v-if="item.application_status == 'snooze'">
                                Snoozed
                                <span v-if="isSnoozeExpired(item.snooze_until)">expired</span>
                                <span v-else>until</span>
                                {{ formatDate(item.snooze_until) }}
                            </div>
                            <div v-else-if="item.application_status == 'approved'">
                              Approved on {{ formatDate(item.last_edited_at) }}
                            </div>
                            <div v-else-if="item.application_status == 'denied'">
                              Denied on {{ formatDate(item.last_edited_at) }}
                            </div>
                            <div v-else>
                              In Inbox
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </BasicStrapCards>
    </div>
    <div id="application-vetter-modals">
        <ModalDialog v-if="showUpdateStatusDialog"
            :title="`${ucfirst(bulkUpdate.statusText.value)} ${formatNumberWithCommas(totalSelectedApplication)} ${recordLabel}?`"
            :close="closeUpdateStatusDialog">
            <Message severity="warn" :closable="false" class="my-2" v-if="isRecordsInCurrentPageSelected">
                <span v-if="!isAllRecordsMatchingFiltersSelected">
                    Changes will be applied to all {{ recordLabel }} on this page.
                </span>
                <span v-else>
                    Changes will be applied to all {{ recordLabel }} in this view.
                </span>
            </Message>
            <div v-if="['approved', 'denied'].includes(bulkUpdate.status.value)">
                <div class="element-container">
                    <label for="outreachCode" class="form-label">Outreach Code</label>
                    <Select :scrollHeight="PV_SCROLL_HEIGHT" v-model="bulkUpdate.outreachCode.value" showClear
                        :options="outreachCodesList" optionValue="id" optionLabel="code" placeholder="-- Select --"
                        class="d-flex" input-id="outreachCode" />
                </div>
                <template v-if="bulkUpdate.status.value === 'approved'">
                    <div class="element-container">
                        <label for="effectiveDate" class="form-label">Effective Date</label>
                        <DatePicker date-format="m/d/yy" input-id="effectiveDate" class="d-flex"
                            v-model="bulkUpdate.effectiveDate.value" />
                    </div>
                    <div class="element-container">
                        <label for="endDate" class="form-label">End Date</label>
                        <DatePicker date-format="m/d/yy" input-id="endDate" class="d-flex"
                            v-model="bulkUpdate.endDate.value" />
                    </div>
                </template>
            </div>
            <div class="element-container" v-if="bulkUpdate.status.value === 'snooze'">
                <label for="snoozeUntil" class="form-label form-label-required">Snooze Until</label>
                <DatePicker date-format="m/d/yy" id="snoozeUntil" class="d-flex" required
                    v-model="bulkUpdate.snoozeUntil.value" />
            </div>
            <div class="element-container">
                <label for="addNote" class="form-label">Add Note</label>
                <Textarea v-model="bulkUpdate.optionalNote.value" id="addNote" :maxlength="MAX_NOTE_LENGTH" />
            </div>
            <template #footer>
                <Button @click="submitBulkUpdateStatus(bulkUpdate.status.value)" id="bulk-update-status"
                    :disabled="bulkUpdate.loading.value">
                    <span class="fa fa-edit" aria-hidden="true" v-if="!bulkUpdate.loading.value"></span>
                    <span class="fa fa-spinner fa-spin" aria-hidden="true" v-if="bulkUpdate.loading.value"></span>
                    Submit
                </Button>
                <Button @click="closeUpdateStatusDialog" severity="secondary">
                    <span class="fa fa-times-circle" aria-hidden="true"></span> Close
                </Button>
            </template>
        </ModalDialog>

        <ModalDialog v-if="bulkNote.showNoteDialog?.value"
            :title="`Add note to ${formatNumberWithCommas(totalSelectedApplication)} ${recordLabel}`"
            :close="closeBulkNoteDialog">
            <Message severity="warn" :closable="false" class="my-2" v-if="isRecordsInCurrentPageSelected">
                <span v-if="!isAllRecordsMatchingFiltersSelected">
                    Changes will be applied to all {{ recordLabel }} on this page.
                </span>
                <span v-else>
                    Changes will be applied to all {{ recordLabel }} in this view.
                </span>
            </Message>
            <div class="element-container">
                <label for="addNote" class="form-label form-label-required">Add Note</label>
                <Textarea v-model="bulkNote.note.value" id="addNote" :maxlength="MAX_NOTE_LENGTH" />
            </div>
            <template #footer>
                <Button @click="submitBulkNote" id="bulk-note" :disabled="bulkNote.loading.value">
                    <span class="fa fa-edit" aria-hidden="true" v-if="!bulkNote.loading.value"></span>
                    <span class="fa fa-spinner fa-spin" aria-hidden="true" v-if="bulkNote.loading.value"></span>
                    Submit
                </Button>
                <Button @click="closeBulkNoteDialog" severity="secondary">
                    <span class="fa fa-times-circle" aria-hidden="true"></span> Close
                </Button>
            </template>
        </ModalDialog>

        <AssignUserDialog :loadFailureHandler="loadFailureHandler"
            :isAllRecordsMatchingFiltersSelected="isAllRecordsMatchingFiltersSelected" :getFilterList="getFilterList"
            :isRecordsInCurrentPageSelected="isRecordsInCurrentPageSelected" v-model:action="selectedBulkAction"
            v-model:totalSelectedRecord="totalSelectedApplication" v-model:selectedItems="selectedItems"
            v-model:userList="userList" @save="saveBulkAssign" @close="closeAssignDialog" />

        <UpdateLabelDialog
            :config="'applications'"
            :loadFailureHandler="loadFailureHandler"
            :isAllRecordsMatchingFiltersSelected="isAllRecordsMatchingFiltersSelected"
            :getFilterList="getFilterList"
            :isRecordsInCurrentPageSelected="isRecordsInCurrentPageSelected" 
            v-model:selectedItems="selectedItems"
            v-model:labelList="labelList" 
            v-model:totalSelectedRecord="totalSelectedApplication" 
            v-model:showModal="showLabelDialog" 
            @save="saveBulkLabel" @close="closeLabelDialog" />

        <ModalDialog v-if="bulkExport.showExportDialog?.value"
            :title="`Export ${formatNumberWithCommas(totalSelectedApplication)} ${recordLabel}?`"
            :close="closeBulkExportDialog">
            <div class="flex flex-wrap gap-3">
                <Message class="mb-3" severity="warn" v-if="bulkExport.export.value === 'pdf'">
                    <b>Please note:</b> Exporting a large number of records may take considerable time to process
                    and can result in a very large file size.
                    We recommend waiting for the completion email before starting
                    another export request.
                </Message>
                <div class="flex align-items-center">
                    <RadioButton v-model="bulkExport.export.value" class="me-2" inputId="csv" value="csv" />
                    <label for="csv">Extract data as CSV file</label>
                </div>
                <div class="flex align-items-center mt-2">
                    <RadioButton v-model="bulkExport.export.value" class="me-2" inputId="pdf" value="pdf" />
                    <label for="pdf">Download data &amp; images as PDF files</label>
                </div>
            </div>

            <template #footer>
                <Button @click="submitBulkExport" id="bulk-export" :disabled="bulkExport.loading.value">
                    <span class="fa fa-download" aria-hidden="true" v-if="!bulkExport.loading.value"></span>
                    <span class="fa fa-spinner fa-spin" aria-hidden="true" v-if="bulkExport.loading.value"></span>
                    Export
                </Button>
                <Button @click="closeBulkExportDialog" severity="secondary">
                    <span class="fa fa-times-circle" aria-hidden="true"></span> Close
                </Button>
            </template>
        </ModalDialog>

        <ModalDialog v-if="showClearSearchConfirmation" title="Confirm Reset Filter"
            :close="() => showClearSearchConfirmation = false">
            Are you sure you want to reset all filters?
            <br>
            <br>
            This action cannot be undone, and all current search filters
            and input will be removed.

            <template #footer>
                <Button @click="resetFilter()" id="reset-filter-confirm">
                    <span class="fa fa-check-circle" aria-hidden="true"></span>
                    Confirm
                </Button>
                <Button @click="showClearSearchConfirmation = false" id="reset-filter-close" severity="secondary">
                    <span class="fa fa-times-circle" aria-hidden="true"></span> Close
                </Button>

            </template>
        </ModalDialog>
    </div>

    <Popover ref="op">
        <UserInfo :userId="selectedUserInfo" />
    </Popover>
</template>

<script setup lang="ts">
import dayjs from "dayjs"
import { ref, onMounted, watch, computed, onUnmounted, nextTick } from 'vue'
import { useAPI } from "@/helpers/services/api"
import { toast } from "@/helpers/toast"
import {
	MAX_NOTE_LENGTH,
	getApiErrorMessage,
	formatDate,
	formatNumberWithCommas,
	ucfirst,
	copyTextToClipboard,
	PV_SCROLL_HEIGHT,
	isSnoozeExpired,
	InternalSettings,
	scrollToView,
	toggleCheckboxes
} from "@/helpers/common"
import type { ApplicationVetter } from "@/helpers/interface/appvet"
import type { DataTableEvent, FilterFields, FilterSchema, OperatorSubstitution, FilterConfig } from "@/helpers/interface/general"
import type { Label } from "@/helpers/interface/candidates"
import ModalDialog from "@/components/Shared/ModalDialog.vue"
import { FilterMatchMode } from '@primevue/core/api'
import Select from 'primevue/select'
import Button from 'primevue/button'
import Chip from 'primevue/chip'
import SplitButton from 'primevue/splitbutton'
import Message from 'primevue/message'
import { useAdminDetails } from "@/stores/adminDetails"
import type { OutreachCodes } from "@/helpers/interface/admin-page"
import Textarea from 'primevue/textarea';
import RadioButton from 'primevue/radiobutton';
import Checkbox from 'primevue/checkbox';
import DatePicker from 'primevue/datepicker'
import BasicStrapCards from "@/components/Shared/BasicStrapCards.vue"
import StrapCardHeaderActions from "@/components/Shared/StrapCardHeaderActions.vue"
import Skeleton from 'primevue/skeleton';
import { useAuth0 } from '@auth0/auth0-vue';
import { ClaimsFields } from "@/helpers/roles"
import {
	performUpdateStatusAction,
	performNoteAction,
	performExportAction,
	ApplicationStatus
} from "@/helpers/bulkActions"
import ApplicationVetterStatusCheckIcon from "@/components/ApplicationVetter/ApplicationVetterStatusCheckIcon.vue"
import { useTaxroll } from "@/stores/taxroll"
import Popover from "primevue/popover"
import UserInfo from "@/components/Shared/UserInfo.vue"
import { usePendoStore } from "@/stores/pendo"
import type { StatusCode } from "@/helpers/bulkActions"
import AssignUserDialog from "@/components/Shared/AssignUserDialog.vue"
import UpdateLabelDialog from "@/components/Shared/UpdateLabelDialog.vue"

const { user } = useAuth0();
const props = defineProps({
	queue: {
		type: Array as () => string[],
		required: true
	}
})
const storeTaxroll = useTaxroll()
const filterConfig = ref<FilterConfig | null>(null)
const isArchiveQueue = computed((): boolean => (props.queue.every(queue => ['approved', 'denied'].includes(queue))))
const isSnoozeQueue = computed((): boolean => (props.queue.every(queue => ['snooze'].includes(queue))))
const isAllQueue = computed((): boolean => (['pending', 'snooze', 'approved', 'denied'].every(queue => props.queue.includes(queue))))
const isAssignedToMeQueue = computed((): boolean => (props.queue.every(queue => ['assigned_to_me'].includes(queue))))
const api = useAPI()
const storeAdminDetails = useAdminDetails()
const emits = defineEmits(["updateStatus", "qsArchive"])
const isFilteringDirty = ref(false)
const showClearSearchConfirmation = ref<boolean>(false)
const loading = ref(true)
const loadingFilterSchema = ref(true)
const filterSchema = ref<FilterSchema[]>([])
const first = ref(0)
const rows = ref(25)
const totalRecords = ref(0)
const data = ref<ApplicationVetter[]>([])
type BulkActionsAvailable = "APPROVED" | "DENIED" | "SNOOZE" | "ASSIGN" | "ASSIGN_TO_ME" | "UNASSIGN" | "ADD_LABEL" | "REMOVE_LABEL" | null
const selectedBulkAction = ref<BulkActionsAvailable>(null)
const dataParams = ref<DataTableEvent | null>(null)
const dataFiltersInitialState = {
	application_id: { value: null, matchMode: FilterMatchMode.CONTAINS },
	parcel_num: { value: null, matchMode: FilterMatchMode.CONTAINS },
	applicant: { value: null, matchMode: FilterMatchMode.CONTAINS },
	flags: { value: null, matchMode: FilterMatchMode.IN },
	tax_year: { value: null, matchMode: FilterMatchMode.IN },
	exemptions: { value: null, matchMode: FilterMatchMode.IN },
	submitted_date: { value: null, matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO },
	application_status_recommendation: { value: null, matchMode: FilterMatchMode.IN },
	application_status: { value: null, matchMode: FilterMatchMode.EQUALS },
	assigned_user_id: { value: null, matchMode: FilterMatchMode.EQUALS },
}
const userFilters = computed(() => (filters.value.filter(obj => obj.field && obj.type)))
const dataFilters = ref({ ...dataFiltersInitialState })

const selectedItems = ref<string[]>([])
const isRecordsInCurrentPageSelected = ref(false)
const isAllRecordsMatchingFiltersSelected = ref(false)
const toggledSelectAll = computed((): boolean => (data.value.length > 0 && data.value.every(data => selectedItems.value.includes(data.tru_id))))
watch(toggledSelectAll, (newValue) => isRecordsInCurrentPageSelected.value = newValue)
const toggleSelectAllMatchingFilters = () => {
	isAllRecordsMatchingFiltersSelected.value = true
	toggleCheckboxes(".p-dataview-content .p-checkbox input", true, true)
}
const totalSelectedApplication = computed(() => {
	if (isAllRecordsMatchingFiltersSelected.value) {
		return totalRecords.value
	}
	if (isRecordsInCurrentPageSelected.value) {
		return data.value.length
	}
	else {
		return selectedItems.value.length
	}
})
const getDefaultFilters = (): {
    field: string;
    type: string;
    value: string | string[];
}[] => {
	if (isAssignedToMeQueue.value) {
		return [
			{ field: "application_status", type: "not in", value: "approved" },
			{ field: "application_status", type: "not in", value: "denied" }
		]
	}
	else {
		return [{ field: "application_status", type: "in", value: props.queue }]
	}
}
const defaultFilters = ref(getDefaultFilters())
let removedDefaultFilter: FilterFields
const operatorSubstitutions: OperatorSubstitution[] = [
	{ type: "date", operator: ">=", text: "Is After" },
	{ type: "date", operator: "<", text: "Is Before" },
]
const recordLabel = computed(() => totalSelectedApplication.value > 1 ? "records" : "record")

const userId = computed(() => {
	return user.value?.sub
})
const initialFilters = [
	{
		field: "",
		type: "",
		value: ""
	},
]

const pendoStore = usePendoStore();
pendoStore.track()

const filters = ref<FilterFields[]>(initialFilters)
const filterSelectedItems = ref<FilterFields[] | null>(null)
const currentPage = ref(1)
const showCheckIcon = computed(() => (props.queue.every(queue => ["pending", "assigned_to_me"].includes(queue))))

// Resets the current page to 1 (UI) whenever the list is filtered
const paginationResetCounter = ref(0)

const handleRemoveFilters = (emitFilter: any) => {
	filters.value = emitFilter.filters
	if (emitFilter.removedDefaultFilter) {
		removedDefaultFilter = emitFilter.removedDefaultFilter
	}
	if (emitFilter.removeAll) {
		defaultFilters.value = []
	}
	isRecordsInCurrentPageSelected.value = false
	loadData(null)
}

const handleSubmitFilters = (emitFilter: any, clearAll?: boolean) => {

	selectedItems.value = [] // clear all selected items
	filters.value = emitFilter.filters
	if (clearAll) {
		// Clear all default filters if in the "Assigned To Me" tab, 
		// otherwise, retain the application status filter.
		defaultFilters.value = isAssignedToMeQueue.value ? [] : [{ field: "application_status", type: "in", value: props.queue }]
	}
	currentPage.value = 1
	paginationResetCounter.value += 1
	// When use clicks the "Clear All", reset selection
	isRecordsInCurrentPageSelected.value = false
	loadData(null, emitFilter.qs)
}

const onSelectAllChange = (checked: boolean = true) => {
	isRecordsInCurrentPageSelected.value = checked
	if (isAllRecordsMatchingFiltersSelected.value) {
		isAllRecordsMatchingFiltersSelected.value = checked
	}
	handleSelectAllChange()
}

const handleSelectAllChange = (add = true) => {
	const currentPageIds = data.value.map(data => data.tru_id)

	if (add) {
		// Add only new unique IDs
		const newItems = currentPageIds.filter(tru_id => !selectedItems.value.includes(tru_id))
		selectedItems.value.push(...newItems)
	} else {
		// Remove all matching IDs from selectedItems
		selectedItems.value = selectedItems.value.filter(tru_id => !currentPageIds.includes(tru_id))
	}
}

const onBulkLabelClick = () => {
	if (checkFiltersDirty())
		return
	showLabelDialog.value = true
}

const onStatusButtonClick = (newStatus: StatusCode) => {
	if (checkFiltersDirty())
		return
	bulkUpdate.status.value = newStatus
}

const onBulkActionClick = (operation: BulkActionsAvailable) => {
	if (checkFiltersDirty())
		return
	selectedBulkAction.value = operation
}

const onAddNoteClick = () => {
	if (checkFiltersDirty())
		return
	bulkNote.showNoteDialog.value = true
}

const onExportClick = () => {
	if (checkFiltersDirty())
		return
	bulkExport.showExportDialog.value = true
}

const onFiltersDirtyChanged = (value: boolean) => {
	isFilteringDirty.value = value
}


const checkFiltersDirty = () => {
	if (isFilteringDirty.value) {
		toast.warning("Your filter changes have not been applied.<br>Please review your choices, and press 'Go'.", { "position": "top" })
		return true
	}
	return false
}

const op = ref()
const selectedUserInfo = ref("")
const displayUserInfo = (event: any, userId: string) => {
	op.value.hide()
	selectedUserInfo.value = userId
	nextTick(() => {
		op.value.show(event)
	})
}

// Outreach List
const outreachCodesList = computed((): OutreachCodes[] => storeAdminDetails.getOutreachList)
const fetchOutreachCodeList = () => storeAdminDetails.fetchOutreachList(loadFailureHandler)

/*** Quick Search ***/
const quickSearchQueryTitle = "Search by applicant, parcel ID, application ID, or address"
const addToQuickSearch = (parcelNum: string) => {
	if (quickSearchQuery.value) quickSearchQuery.value += `, ${parcelNum}`
	else quickSearchQuery.value = parcelNum
}
const quickSearchQuery = ref<string>(storeTaxroll.getQuickSearchQuery || "")
const showQSMessage = computed(() => !loading.value && filteringWithQS.value && quickSearchQuery.value)
const filteringWithQS = ref<boolean>(false)
const qsIncludeArchive = ref<boolean>(false)
const quickSearchArchive = async () => {
	storeTaxroll.setQuickSearchQuery(quickSearchQuery.value)
	emits("qsArchive")
	await initializeApp()
}

/*** Bulk Label ***/
const showLabelDialog = ref(false)
const labelList = ref<Label[]>([])
const saveBulkLabel = async (requestMade: boolean) => {
	if (requestMade) {
		toast.success("Your submission was successful")
		loadData(null, filteringWithQS.value)
		resetToInitialState()
		closeLabelDialog()
	}
}
const closeLabelDialog = () => selectedBulkAction.value = null

/*** Bulk Assign ***/
const userList = computed(() => storeAdminDetails.getUsersList)
const fetchUsersList = async () => await storeAdminDetails.fetchUsersList(loadFailureHandler)
const assignActionItems = [
	{ label: 'Assign', command: () => onBulkActionClick("ASSIGN") },
	{
		label: 'Assign to me',
		command: () => onBulkActionClick("ASSIGN_TO_ME"),
		disabled: !(user.value?.[ClaimsFields['app_metadata']]?.is_client)
	},
]
const saveBulkAssign = async (requestMade: boolean) => {
	if (requestMade) {
		toast.success("Your submission was successful")
		loadData(null, filteringWithQS.value)
		resetToInitialState()
		closeAssignDialog()
	}
}
const closeAssignDialog = () => selectedBulkAction.value = null

/*** Bulk Update Status ***/
let snoozeDays = 0
const bulkUpdate = {
	prevStatus: ref<string[]>(props.queue),
	status: ref<StatusCode | "">(""),
	optionalNote: ref(""),
	loading: ref(false),
	snoozeUntil: ref<string>(""),
	outreachCode: ref<number | null>(null),
	effectiveDate: ref<string | null>(null),
	endDate: ref<string | null>(null),
	statusText: computed(() => {
		switch (bulkUpdate.status.value) {
			case ApplicationStatus.Approved:
				return "Approve";
			case ApplicationStatus.Denied:
				return "Deny";
			case ApplicationStatus.Snooze:
				return "Snooze";
			default:
				return "";
		}
	}),
	parentURL: "/applications"
}
watch(() => bulkUpdate.status.value, async (status: string) => {
	if (status === ApplicationStatus.Snooze) {
		// Default snooze date: today + X days
		bulkUpdate.snoozeUntil.value = dayjs().add(snoozeDays, "day").format("MM/DD/YYYY")
	}
})
const showUpdateStatusDialog = computed(() => (bulkUpdate.status.value !== ""))
const submitBulkUpdateStatus = async (status: StatusCode | "") => {
	if (!status) return
	const additionalParam = (isAllRecordsMatchingFiltersSelected.value ? {
		filters: getFilterList()
	} : {
		tru_ids: selectedItems.value
	})
	const result = await performUpdateStatusAction(api, status, bulkUpdate, additionalParam)
	if (result === "success") {
		clearAllSelectedItems(false)
		emits("updateStatus", {
			prevStatus: bulkUpdate.prevStatus.value,
			status: bulkUpdate.status.value,
		})
		loadData(null, filteringWithQS.value)
		resetToInitialState()
		closeUpdateStatusDialog()
	}
	bulkUpdate.loading.value = false
}
const closeUpdateStatusDialog = () => {
	selectedBulkAction.value = null
	bulkUpdate.status.value = ""
	bulkUpdate.outreachCode.value = null
	bulkUpdate.effectiveDate.value = null
	bulkUpdate.endDate.value = null
	bulkUpdate.optionalNote.value = ""
}

/*** Bulk Note ***/
const bulkNote = {
	note: ref(""),
	loading: ref(false),
	showNoteDialog: ref(false),
	parentURL: "/applications"
}
const submitBulkNote = async () => {
	const additionalParam = (isAllRecordsMatchingFiltersSelected.value ? {
		filters: getFilterList()
	} : {
		tru_ids: selectedItems.value
	})
	const result = await performNoteAction(api, bulkNote, additionalParam)
	if (result === "success") {
		loadData(null, filteringWithQS.value)
		resetToInitialState()
		closeBulkNoteDialog()
	}
	bulkNote.loading.value = false
}
const closeBulkNoteDialog = () => {
	selectedBulkAction.value = null
	bulkNote.note.value = ""
	bulkNote.showNoteDialog.value = false
}

/*** Bulk Export ***/
const bulkExport = {
	export: ref<"csv" | "pdf">("csv"),
	loading: ref(false),
	showExportDialog: ref(false),
	parentURL: "/applications"
}
const submitBulkExport = async () => {
	const additionalParam = (isAllRecordsMatchingFiltersSelected.value ? {
		filters: getFilterList()
	} : {
		tru_ids: selectedItems.value
	})
	const result = await performExportAction(api, props.queue, bulkExport, additionalParam)
	if (result === "success") {
		resetToInitialState()
		closeBulkExportDialog()
	}
	bulkExport.loading.value = false
}
const closeBulkExportDialog = () => {
	selectedBulkAction.value = null
	bulkExport.export.value = "csv"
	bulkExport.showExportDialog.value = false
}


const loadFailureHandler = (error: any) => {
	const message = getApiErrorMessage(error, { "featureName": "Report" })
	toast.error(message)
}
const initFilters = () => {
	dataFilters.value = dataFiltersInitialState
}
const resetFilter = async () => {
	showClearSearchConfirmation.value = false
	dataParams.value = null
	resetToInitialState()
	initFilters()
	rows.value = 25
	await loadData()
}

const clearSelection = () => {
	isAllRecordsMatchingFiltersSelected.value = false
	selectedBulkAction.value = null
	handleSelectAllChange(false)
	toggleCheckboxes(".p-dataview-content .p-checkbox input")
	isRecordsInCurrentPageSelected.value = false
}

const resetToInitialState = () => {
	// If either filtering is applied or there are user filters
	if (filteringWithQS.value || userFilters.value.length) {
		// Reset selected items and clear selection
		selectedItems.value = []
		clearSelection()
		return
	}
}
const isShowAllSelectedItems = ref(false)
const showAllSelectedItems = () => {
	isShowAllSelectedItems.value = true
	filterSelectedItems.value = [
		{
			field: "tru_id",
			type: "in",
			value: selectedItems.value
		}
	]
	first.value = 0
	currentPage.value = 1
	paginationResetCounter.value += 1
	loadData(null)
}

const clearAllSelectedItems = (refreshList: boolean = true) => {
	isShowAllSelectedItems.value = false
	selectedItems.value = []
	filterSelectedItems.value = []
	isRecordsInCurrentPageSelected.value = false
	isAllRecordsMatchingFiltersSelected.value = false
	if (refreshList) loadData()
	toggleCheckboxes(".p-dataview-content .p-checkbox input")
}

// For Dynamic ScrollHeight Calculation
const appVetDataView = ref<HTMLElement | null>(null)

const sortTitles = ref({
	asc: "Sort Oldest First",
	desc: "Sort Newest First"
})
const sortOrder = ref<"desc" | "asc">("desc")
const changeSortOrder = () => {
	const queue = props.queue[0]
	const ascQueues = ["questionnaire", "unqualified", "snooze"]
	const defaultSortOrder = ascQueues.includes(queue) ? "asc" : "desc"

	if (sortOrder.value === defaultSortOrder) {
		sortOrder.value = defaultSortOrder === "asc" ? "desc" : "asc"
	}
	else {
		sortOrder.value = defaultSortOrder
	}
	loadData(null, filteringWithQS.value)
}

const defaultSortOrder = (): "desc" | "asc" => {
	const ascQueues = ["snooze"];
	const defaultSortOrder = props.queue.every(queue => ascQueues.includes(queue)) ? "asc" : "desc";
	return defaultSortOrder
}

const getSortList = (event: any) => {
	let sort = [{ "field": "submitted_date", "dir": sortOrder.value }]
	let customSortOrder = sortOrder.value

	if (isSnoozeQueue.value) {
		sortTitles.value = {
			asc: "Snooze Until: Earliest First",
			desc: "Snooze Until: Latest First",
		}
		customSortOrder = defaultSortOrder() === sortOrder.value ? "desc" : "asc"
		sort = [{ "field": "snooze_until", "dir": customSortOrder }]
	}
	else if (event.sortField) {
		sort = [{ "field": event.sortField, "dir": event.sortOrder == 1 ? "asc" : "desc" }]
	}
	return sort
}

const getListPayload = (event: any) => {
	return {
		"filter": getFilterList(),
		"page": event?.page,
		"page_size": event?.rows,
		"sort": getSortList(event),
		"include_total": false
	}
}

const getFilterList = () => {
	let output: FilterFields[] = []

	// Filtering using quick search
	if (filteringWithQS.value && quickSearchQuery.value) {
		const qsQuery = {
			field: "quick_search",
			type: "=",
			value: quickSearchQuery.value
		}
		output.push(qsQuery)

		// Reset all filter selections made by the user
		filters.value = []
	}

	// Filtering using filter options/section
	else {
		if (isShowAllSelectedItems.value && filterSelectedItems.value?.length) {
			output = filterSelectedItems.value
		}
		else output = [...userFilters.value]

		// Clear quick search query
		quickSearchQuery.value = ""
	}

	// Remove any default filters that the user has requested to be removed.
	if (removedDefaultFilter) {
		defaultFilters.value = defaultFilters.value.filter(o =>
			!(o.field === removedDefaultFilter.field &&
                o.type === removedDefaultFilter.type &&
                o.value === removedDefaultFilter.value)
		)
	}

	// Add default filters
	if (defaultFilters.value.length) {
		// Check if the default filter already exists, based on the 
		// exact filter field, type, and value
		for (const filter of defaultFilters.value) {
			// If it doesn't exist, push the filter to the output
			if (!output.some(item => item.field === filter.field
                && item.type === filter.type
                && item.type === filter.value)) {
				output.push(filter)
			}
		}
	}

	const userAssignedFilter: FilterFields = { field: "assigned_to", type: "in", value: [userId.value] }
	// Always include the assigned_to filter when in "Assigned To Me" tab
	if (userId.value && isAssignedToMeQueue.value) {
		output.push(userAssignedFilter)
	}
	return output
}

const loadData = async (event: any = null, qs: boolean = false) => {
	loading.value = true
	dataParams.value = { ...dataParams.value, page: event?.page || currentPage.value, rows: event?.rows || 25 }
	filteringWithQS.value = qs
	let payload = getListPayload(dataParams.value)

	const requests = []
	const listRequest = api.post(`/applications/`, payload)
		?.then(response => {
			data.value = response.data?.data || []
		})
		?.catch(error => {
			data.value = []
			toast.error(getApiErrorMessage(error))
		})
	requests.push(listRequest)

	const countPayload = payload.filter
	const countRequest = api.post("/applications/count", countPayload)
		?.then(response => {
			totalRecords.value = response.data.count || 0
		})
		?.catch(error => {
			toast.error(getApiErrorMessage(error))
		})
	requests.push(countRequest)

	await Promise.all(requests)

	if (isAllRecordsMatchingFiltersSelected.value) {
		handleSelectAllChange()
		toggleCheckboxes(".p-dataview-content .p-checkbox input", true, true)
	}

	loading.value = false
}

const onPage = async (event: DataTableEvent) => {
	if (isFilteringDirty.value) {
		toast.warning("Your current filter settings are unsaved. <br> To see updates, please apply them by pressing 'Apply,' as changes may not be reflected on this page.", { "position": "top", "duration": 5000 })
		return
	}
	event.page += 1
	dataParams.value = event
	currentPage.value = event.page
	rows.value = event.rows as number
	await loadData(event, filteringWithQS.value)
	isRecordsInCurrentPageSelected.value = false
	if (isAllRecordsMatchingFiltersSelected.value) {
		handleSelectAllChange()
		toggleCheckboxes(".p-dataview-content .p-checkbox input", true, true)
	}
	scrollToView("row_0")
}

const fetchLabelsDropdownList = async () => {
	try {
		const response = await api.get('/labels/?applications=true');
		const items = response.data ? response.data : []
		labelList.value = items as Label[]
	}
	catch (error: any) {
		labelList.value = [];
		toast.error(getApiErrorMessage(error))
	}
}


const setFilterConfig = () => {
	filterConfig.value = {
		currentFilteredFields: userFilters.value.length,
		activeFilters: filters.value,
		filterSchema: filterSchema.value,
		operatorSubstitutions: operatorSubstitutions,
		defaultFilters: isAssignedToMeQueue.value ? defaultFilters.value : [],
		showDefaultFilters: isAssignedToMeQueue.value ? true : false,
	}
}

const fetchFilterSchema = async () => {
	loadingFilterSchema.value = true
	try {
		const response = await api.get('/applications/filters');
		filterSchema.value = response.data as FilterSchema[]
	}
	catch (error: any) {
		filterSchema.value = [];
		toast.error(getApiErrorMessage(error))
	}
	loadingFilterSchema.value = false
}

const getDefaultSnoozeDays = async () => snoozeDays = await storeAdminDetails.getDefaultSnoozeDays(InternalSettings.application_default_snooze_days)

watch(
	[filters, filterSchema],
	() => { setFilterConfig() },
	{ deep: true }
)

const initializeApp = async () => {
	dataParams.value = {
		first: 0,
		rows: 25,
		page: 1,
		sortField: null,
		sortOrder: null,
		filters: dataFilters.value
	}
	initFilters()
	getDefaultSnoozeDays()
	await loadData(null, !!quickSearchQuery.value)
	fetchFilterSchema()
	fetchLabelsDropdownList()
	fetchUsersList()
	fetchOutreachCodeList()
}

onMounted(async () => {
	await initializeApp()
})

onUnmounted(() => {
	quickSearchQuery.value = ""
	filteringWithQS.value = false
	qsIncludeArchive.value = false
	storeTaxroll.setQuickSearchQuery("")
})
</script>

<style>
@import "@/assets/appvet.css";
</style>
