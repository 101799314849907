<template>
    <div id="signature-container" class="signature-container">
        <canvas id="signature-canvas" class="signature-canvas" height="80" width="340" ref="canvasEl"
            @mousedown="canvasMouseDownHandler" @mousemove="canvasMouseMoveHandler"
            @touchstart="canvasTouchStartHandler" @touchmove="canvasTouchMoveHandler">
        </canvas>
        <div class="toolbar text-end">
            <button type="button" id="clear-signature-button" class="public-button" @click="clearButtonClickHandler">
                Clear Signature
            </button>
        </div>
    </div>
</template>


<script setup lang="ts">
import { ref, onMounted } from "vue"

const signature_of_applicant = defineModel<string | null>("signature_of_applicant")

const canvasEl = ref<HTMLCanvasElement>()
const position = { "x": 0, "y": 0 }


const canvasMouseDownHandler = (e: MouseEvent) => {
	setPosition(e.clientX, e.clientY)
}

const canvasMouseMoveHandler = (e: MouseEvent) => {
	if (e.buttons === 1) {
		drawLine(e.clientX, e.clientY)
	}
}

const canvasTouchStartHandler = (e: TouchEvent) => {
	e.preventDefault()
	const touch = e.touches[0]
	setPosition(touch.clientX, touch.clientY)
}

const canvasTouchMoveHandler = (e: TouchEvent) => {
	e.preventDefault()
	const touch = e.touches[0]
	drawLine(touch.clientX, touch.clientY)
}

const getBase64StringFromDataURL = (dataURL: any) => dataURL.replace('data:', '').replace(/^.+,/, '');
const saveCanvasToString = () => {
	if (canvasEl.value) {
		const dataURL = canvasEl.value.toDataURL();
		// Convert to Base64 string
		const base64 = getBase64StringFromDataURL(dataURL);
		signature_of_applicant.value = base64
	}
}

const clearButtonClickHandler = () => {
	if (!canvasEl.value)
		throw "Canvas context not found in drawLine"

	setupCanvasBackground()
	signature_of_applicant.value = ""
}

const drawLine = (x: number, y: number) => {
	const context = getContext()

	context.beginPath()
	context.lineWidth = 2
	context.lineCap = "round"
	context.strokeStyle = "#000"

	context.moveTo(position.x, position.y)
	setPosition(x, y)
	context.lineTo(position.x, position.y)
	context.stroke()

	setTimeout(() => saveCanvasToString(), 1000)

}

const getContext = () => {
	const context = canvasEl.value && canvasEl.value.getContext("2d")
	if (!context)
		throw "Canvas has no context in drawLine"
	return context
}

const setPosition = (x: number, y: number) => {
	if (!canvasEl.value)
		return;

	const rect = canvasEl.value.getBoundingClientRect()
	position.x = x - rect.left
	position.y = y - rect.top
}

const setupCanvasBackground = () => {
	/* set background of the canvas to white.  we will 
        draw the signature in contrasting black. The 
        non-transparent background will allow us to overlay 
        the signature if the PDF without having to manipulate 
        the background color first
    */
	if (!canvasEl.value)
		throw "Canvas reference is not valid";

	const context = getContext()
	context.clearRect(0, 0, canvasEl.value.width, canvasEl.value.height)
	context.fillStyle = "#fff";
	context.fillRect(0, 0, canvasEl.value.width, canvasEl.value.height);
}

onMounted(() => {
	const container = document.getElementById("signature-container");
	if (container && canvasEl.value) {
		/* Adjust the canvas element with to the size of its container.
            We use height/width attributes instead of CSS to preserve 
            canvas contents when viewport orientation/size changes
        */
		const rect = container.getBoundingClientRect()
		canvasEl.value.setAttribute("width", rect.width.toString())

		setupCanvasBackground()
	} else {
		throw "Setup error: cannot find canvas or it's container"
	}
})

</script>
